.content-main {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	position: fixed;
	bottom: 0;
	top: 0;
	left: 0;
	right: 0;
	width: 100%;
	height: 100%;
	padding: 25px;
	box-shadow: 0px 5px 25px #52575D10;

	.main {
		flex: 0 0 80%;
        max-width: 80%;
        height: 100%;
        margin-left: 10px;
        background: #fff;
		border-radius: 20px;
        padding: 20px;
        position: relative;

				> div {
					height: 100%;
				}

		.top-page {
			display: flex;
			flex-direction: row;
			align-items: center;

			.name-page {
				margin: 0;
				margin-right: auto;
				color: #333;
				font-size: 24px!important;
				font-weight: bold;
			}

			.fun-icons {
				display: flex;
				flex-direction: row;
				align-items: center;

				.fun-item {
					padding: 0 10px;

					.fun-item-icon {
						width: 26px;
						height: 26px;
					}
				}
			}
		}

		.content-page {
			position: relative;
			width: 100%;
			height: calc(100% - 166px);
		}
	}
}

hr {
	border: 0;
	background: #EBF2F7;
	height: 3px;
}

.table {
	width: 100%;

	.thead {
		background: #E3E9EF;
		border-radius: 10px;

		tr {
			th {
				border: 0;
				font-weight: normal;
				font-size: 12px;

				&:first-child {
					border-top-left-radius: 10px;
					border-bottom-left-radius: 10px;
				}

				&:last-child {
					border-top-right-radius: 10px;
					border-bottom-right-radius: 10px;
				}
			}
		}
	}

	tbody {
		tr {
			border: 0!important;
			transition: .3s ease;

			&:hover {
				background: #f2f4f7;

			}

			td {
				border: 0!important;
				font-size: 12px;
				vertical-align: middle!important;
				padding: 1em 5px!important;
				cursor: pointer;

				&:first-child {
					border-top-left-radius: 10px;
					border-bottom-left-radius: 10px;
				}

				&:last-child {
					border-top-right-radius: 10px;
					border-bottom-right-radius: 10px;
				}

				.item-user-blank {
					width: 32px;
					height: 32px;
					margin-right: 10px;
				}

				input[type=checkbox] {
					transform: scale(1.5);
				}
			}
		}
	}
}


ul {
	margin-bottom: 0!important;
}
