.preview-item {
    border-radius: 20px;
	box-shadow: 0px 5px 25px #52575D10;
    background: #fff;
    padding: 15px;
    border: 1px solid #EBF2F7;

    .preview-top {
        display: flex;
        align-items: center;

        img {
            margin-right: 10px;
        }

        a {
            margin-left: auto;
        }
    }

    .preview-table {
        table {
            margin-bottom: 0!important;

            td {
                border: 0 !important;
                font-size: 10px!important;
                vertical-align: middle !important;
                padding: .5em 2px !important;
                cursor: pointer;
            }

            th {
                font-size: 10px!important;
                padding: .5em 10px!important;
            }
        }
    }
}
