.sidebar {
	flex: 0 0 20%;
  max-width: 20%;
  height: 100%;
  margin-right: 10px;
  background: #fff;
	border-radius: 20px;
	padding: 15px 25px;
	box-shadow: 0px 5px 25px #52575D10;
	overflow: auto;

	.sidebar-top {
		display: flex;
		flex-direction: row;
		align-items: center;

		.sidebar-top-logo {
			width: 28px;
			height: 28px;
			margin-left: auto;
		}

		.sidebar-top-user-auth {
			font-weight: bold;
			font-size: 14px;
			color: #333;
			margin-bottom: 0!important;
			margin-left: 10px;
		}

		.btn {
			margin-left: auto;
			font-size: 14px;
			font-weight: bold;
			padding: 5px;
		}
	}

	.sidebar-menu {
		height: 100%;
		width: 100%;

		.sidebar-item {
			display: flex;
			align-items: center;
			flex-direction: row;
			width: 100%;
			padding: 10px 15px;
			transition: .3s ease;
			border-radius: 100px;
			text-decoration: none;

			&.disabled {
				opacity: 0.2;

				&:hover {
					opacity: 0.2;
					background-color: #fff;
					color: #52575D!important;
				}
			}

			.sidebar-item-icon {
				margin-right: 10px;
				color: #52575D!important;
				height: 30px;
			}

			span {
				font-size: 14px;
				color: #52575D!important;
				font-weight: 600;
				transition: .3s ease;
				text-decoration: none;
			}

			&:hover {
				background: #0012EB;

				span {
					color: #fff!important;
				}
			}
		}
	}
}
