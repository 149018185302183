.home-page-user-auth {
	position: absolute;
	top: 0; right: 0; left: 0; bottom: 0;
	height: 100%;
	width: 100%;
	z-index: 0;

	> .row {
		height: 100%;
	}

	.cips-logo {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		background: $primary-color;

		p.cips-logo-copy-title {
			color: #fff;
			font-size: 36px;
			font-weight: bold;
			text-align: center;
			margin-top: 25px;
		}

		p.cips-logo-copy-text {
			color: #fff;
			font-size: 14px;
			font-weight: 400;
			text-align: center;
		}
	}

	.cips-registration-form {
		padding: 20px 10%;
	}

	.cips-login-form {
		padding: 20px 15%;

		.remember_me {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;
			font-weight: 400;
			font-size: 12px;

			input[type=checkbox] {
				margin-right: 10px;
				-ms-transform: scale(1.5); /* IE */
				-moz-transform: scale(1.5); /* FF */
				-webkit-transform: scale(1.5); /* Safari and Chrome */
				-o-transform: scale(1.5); /* Opera */
				transform: scale(1.5);
				padding: 10px;
			}
		}
	}

	.cips-registration-form, .cips-login-form {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		.cips-title {
			font-size: 24px;
			font-weight: bold;
			text-align: center;
			margin-bottom: 5px;
		}

		.cips-question-auth {
			font-size: 13px;
			font-weight: normal;
			text-align: center;
		}

		label {
			font-size: 12px;
			color: #000;
			margin-bottom: 5px;
			margin-top: 10px;
		}

		.form-control {
			padding: 1.2em .5em;
		}

		input[type=submit] {
			margin-top: 25px;
			padding: 1em .5em;
			background: $primary-color;
			border-color: $primary-color!important;
		}

		.cips-info {
			font-size: 10px;
			text-align: center;
			margin-top: 20px;
		}
	}
}


@media (max-width: 1080px) {
	.home-page-user-auth {
		.cips-login-form {
			padding: 20px 10%;
		}
	}
}
