// Body
$primary-color: #0012EB;
$primary-dark-color: #2b99c1;


$secondary-color: #0012EB;
$secondary-dark-color: #437ede;


$success-color: #45d475;
$success-dark-color: #35aa5c;

$danger-color: #d44545;
$danger-dark-color: #aa3535;
