.item-btn-dash {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 50px;
    border-radius: 20px;
	box-shadow: 0px 5px 25px #52575D10;
    background: #fff;
    border: 1px solid #EBF2F7;
    transition: .3s ease;

    img {
        width: 48px;
        margin: 0 auto;
    }

    p {
        text-align: center;
        font-size: 18px;
        font-weight: bold;
        color: #222;
        margin-top: 20px;
    }

    &:hover {
        cursor: pointer;
	    box-shadow: 0px 5px 25px #52575d45;
    }
}
