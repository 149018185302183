// .carousel-promo {
//   width: 100%;
//   overflow: hidden;
//   display: flex;
//   align-items: center;
//   margin-bottom: 30px;
//   margin-top: 20px;
//   transition: all .3s ease;

//   .promo-item {
//     margin: 0 15px;
//     min-width: 85%;
//     object-fit: cover;
    
//     &:first-of-type {
//       margin-left: 0;
//     }
    
//     img {
//       border-radius: 50px;
//       max-height: 430px;
//       width: 100%;
//     }
//   }
// }

.carousel {
  margin-bottom: 20px;
}

.carousel-item {
  width: 100%;
  img {
    width: 100%;
    object-fit: cover;
    max-height: 430px;
    border-radius: 25px;
  }
}

.visually-hidden {
  display: none;
}