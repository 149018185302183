.text-min {
  font-size: 15px;
  color: #666;
  position: relative;
  bottom: 0;

  margin-top: auto;

  &.no-absolute {
    position: static!important;
    margin-top: 50px;
  }
}


.renewal-request-flex {
  display: flex;
  flex-direction: column;
}