@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700;800&display=swap");

* {
    font-family: "Montserrat", sans-serif;
}

// variables
@import "variables";

// modules
@import "modules/auth";
@import "modules/base";

body {
    background-color: #0012EB !important;
}

a {
    color: $primary-color;
    font-weight: bold;
}

.btn-primary {
    background: $primary-color !important;
    border-color: $primary-color !important;
    color: #fff !important;

    &:hover {
        background: $primary-dark-color !important;
    }

    &:focus {
        background: $primary-dark-color !important;
    }
}

.btn-secondary, .badge-secondary {
    background: $secondary-color !important;
    border-color: $secondary-color !important;
    color: #fff !important;
    padding: 0.5em;
    font-size: 13px;
    font-weight: bold;

    &:hover {
        background: $secondary-dark-color !important;
    }

    &:focus {
        background: $secondary-dark-color !important;
    }
}

.btn-success {
    background: $success-color !important;
    border-color: $success-color !important;
    color: #fff !important;
    padding: 0.5em;
    font-size: 13px;
    font-weight: bold;

    &:hover {
        background: $success-dark-color !important;
    }

    &:focus {
        background: $success-dark-color !important;
    }
}

.btn-danger {
    background: $danger-color !important;
    border-color: $danger-color !important;
    color: #fff !important;
    padding: 0.5em;
    font-size: 13px;
    font-weight: bold;

    &:hover {
        background: $danger-dark-color !important;
    }

    &:focus {
        background: $danger-dark-color !important;
    }
}

.open-more {
    background: $secondary-color !important;
    border-color: $secondary-color !important;
    color: #fff !important;
    padding: 4px 5px;
    border-radius: 4px;
    margin-right: 5px;
    border: 0;

    img {
        width: 12px;
    }

    &:hover {
        background: $secondary-dark-color !important;
    }

    &:focus {
        background: $secondary-dark-color !important;
    }
}

.pagination {
    .page-item {
        .page-link {
            font-weight: bold;
            font-size: 14px;
            color: $secondary-color;
        }
    }
}

// loader
.loader {
    color: #000;
    font-size: 50px;
    overflow: hidden;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    position: absolute;
    -webkit-animation: load6 1.7s infinite ease, round 1.7s infinite ease;
    animation: load6 1.5s infinite ease, round 1.5s infinite ease;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
@-webkit-keyframes load6 {
    0% {
        box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
            0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
    5%,
    95% {
        box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
            0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
    10%,
    59% {
        box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em,
            -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em,
            -0.297em -0.775em 0 -0.477em;
    }
    20% {
        box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em,
            -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em,
            -0.749em -0.34em 0 -0.477em;
    }
    38% {
        box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em,
            -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em,
            -0.82em -0.09em 0 -0.477em;
    }
    100% {
        box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
            0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
}
@keyframes load6 {
    0% {
        box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
            0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
    5%,
    95% {
        box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
            0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
    10%,
    59% {
        box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em,
            -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em,
            -0.297em -0.775em 0 -0.477em;
    }
    20% {
        box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em,
            -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em,
            -0.749em -0.34em 0 -0.477em;
    }
    38% {
        box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em,
            -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em,
            -0.82em -0.09em 0 -0.477em;
    }
    100% {
        box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
            0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
}
@-webkit-keyframes round {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes round {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.dashborad-contents {
    overflow: auto;
    position: absolute;
    top: 75px;
    left: 20px;
    right: 20px;
    bottom: 22px;
    max-height: 100%;
}

.loader-square,
.loader-square:before,
.loader-square:after {
  background: #000;
  -webkit-animation: load1 1s infinite ease-in-out;
  animation: load1 1s infinite ease-in-out;
  width: 1em;
  height: 4em;
}
.loader-square {
    color: #000;
    position: absolute;
    text-indent: -9999em;
    font-size: 11px;
    transform: translateZ(0);
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.loader-square:before,
.loader-square:after {
  position: absolute;
  top: 0;
  content: '';
}
.loader-square:before {
  left: -1.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loader-square:after {
  left: 1.5em;
}
@-webkit-keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
@keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}

.single-tickets {
    .text-bold {
        margin: 0;
        font-weight: bold;
    }

    .text-normal {
        margin-bottom: 10px;
    }

    h2 {
        font-weight: bold;
        font-size: 22px;
    }

    .comment-item {
        background-color: #eee;
        border-radius: 20px;
        padding: 20px 30px;
        border-collapse: collapse;
        margin-bottom: 10px;

        .author {
            margin: 0;
            margin-bottom: 10px;
            font-weight: bold;

            .time {
                font-weight: normal;
            }
        }

        .comment {
            margin: 0;
        }
    }
}