.help-request-wrapper {
    background: #0003;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;

    .help-request {
        background-color: #fff;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 20px;
        z-index: 99999;
        width: 75%;
        height: 70%;
        border-radius: 16px;
        border: 1px solid #eee;
        overflow: auto;

        label {
            font-weight: bold;
            margin-top: 10px;
        }

        textarea {
            height: 300px;
        }


        .help-request-top {
            display: flex;
            flex-direction: row;
            align-items: center;

            h2 {
                margin: 0;
                margin-right: auto;
                color: #333;
                font-size: 24px!important;
                font-weight: bold;
            }
            a {
                margin-left: auto;
                font-size: 22px;
                text-decoration: none;
                background: #EBF2F7;
                padding: 10px;
                border-radius: 8px;
                line-height: 0.3;
                color: #999!important;
            }
        }
    }
}
